import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { arrayOf, func, node, number, object, shape, string } from 'prop-types';
import { H2, H3, LinkedLogo, NamedLink } from '../../../../components';
import SectionContainer from '../SectionContainer';

import css from './SectionFooter.module.css';
import ArrowIcon from './img/arrow.svg';
import BImage from './img/dark-b.svg';
import LightBImage from './img/light-b.svg';
import InstagramIcon from './img/instagram.svg';
import FacebookIcon from './img/facebook.svg';
import ArrowDownIcon from './img/arrow-down.svg';

const MAX_MOBILE_SCREEN_WIDTH = 1024;

const cities = ["London", "Bristol", "Manchester", "Edinburgh", "Brighton", "Liverpool", "Belfast"];
const treatments = [
  {
    city: "London",
    treatment: ["Spa", "Hair", "Aesthetics", "Facial", "Lips", "Laser"],
  },
  {
    city: "Bristol",
    treatment: ["Aesthetics", "Facial", "Lips", "Laser"],
  },
  {
    city: "Manchester",
    treatment: ["Spa", "Facial", "Lips", "Laser"],
  },
  {
    city: "Edinburgh",
    treatment: ["Spa", "Hair", "Lips", "Laser"],
  },
  {
    city: "Brighton",
    treatment: ["Spa", "Hair", "Aesthetics", "Laser"],
  },
  {
    city: "Liverpool",
    treatment: ["Spa", "Hair", "Aesthetics", "Facial", "Lips", "Laser"],
  },
  {
    city: "Belfast",
    treatment: ["Spa", "Hair", "Aesthetics", "Facial"],
  },
];

const getTreatmentsForCity = (city) => {
  const cityTreatments = treatments.find(treatment => treatment.city === city);
  return cityTreatments ? cityTreatments.treatment : [];
};

// Section component that's able to show blocks in multiple different columns (defined by "numberOfColumns" prop)
const SectionFooter = props => {
  const {
    sectionId,
    className,
    rootClassName,
    appearance,
    options,
    linkLogoToExternalSite,
  } = props;

  const [activeCity, setActiveCity] = useState(cities[0]);

  const cityTreatments = getTreatmentsForCity(activeCity);

  // If external mapping has been included for fields
  // E.g. { h1: { component: MyAwesomeHeader } }
  const fieldComponents = options?.fieldComponents;
  const fieldOptions = { fieldComponents };

  const hasMatchMedia = typeof window !== 'undefined' && window?.matchMedia;
  const isMobileLayout = hasMatchMedia
    ? window.matchMedia(`(max-width: ${MAX_MOBILE_SCREEN_WIDTH}px)`)?.matches
    : true;
  const logoLayout = isMobileLayout ? 'mobile' : 'desktop';

  return (
    <SectionContainer
      as="footer"
      id={sectionId}
      className={className || css.root}
      rootClassName={rootClassName}
      appearance={appearance}
      options={fieldOptions}
    >
      <img className={css.backgroundImage} src={BImage} />
      <div className={css.footer}>
        <div className={css.header}>
          <div className={css.section1}>
            <LinkedLogo
              rootClassName={css.logoLink}
              logoClassName={css.logoWrapper}
              logoImageClassName={css.logoImage}
              linkToExternalSite={linkLogoToExternalSite}
              layout={logoLayout}
            />
          </div>

          <div className={css.section2}>
            {/* <label className={css.subtitle}>Information</label> */}

            <span>Privacy</span>
            <span>FAQ</span>
            <span>Contact</span>
          </div>
          <div className={css.section3}>
            <a href='https://www.instagram.com/heyblumi/'>
              <div className={css.roundContainer}>
                <img src={InstagramIcon} />
              </div>
            </a>

            <a href='https://www.facebook.com/people/Blumi/61556755837352/'>
              <div className={css.roundContainer}>
                <img src={FacebookIcon} />
              </div>
            </a>
          </div>
          {/* <div className={css.section3}>
            <label className={css.subtitle}>Menu</label>
            <ul>
              <li>For a couple</li>
              <li>For him</li>
              <li>For her</li>
              <li>Treat someone you love ❤️</li>
            </ul>
          </div> */}
          <div className={css.section4}>
            <NamedLink name="SignupPagePartner" className={css.signupPartner}>
              <FormattedMessage id="Footer.signupPartner" />
            </NamedLink>
            <NamedLink name="LoginPage" className={css.login}>
              <FormattedMessage id="Footer.login" />
            </NamedLink>
            <NamedLink name="SignupPageModel" className={css.signupModel}>
              <FormattedMessage id="Footer.signupModel" />
            </NamedLink>
          </div>
        </div>
        {/* <div className={css.content}>
          <H3 className={css.title}>
            <FormattedMessage
              id="Footer.title"
              values={{ lineBreak: <br /> }}
            />
          </H3>
          <div className={css.cities}>
            {cities.map((city, index) =>
              <span
                className={`${css.city} ${activeCity === city ? css.activeCity : ''}`}
                key={index}
                onClick={() => setActiveCity(city)}
              >
                {city}
              </span>
            )}
            <div className={css.divider} />
          </div>
          <div className={css.treatments}>
            {cityTreatments.map((treatment, index) => (
              <span key={index} className={css.treatment}>
                <strong>
                  {treatment}
                </strong>
                <br />
                Treatment details
              </span>
            ))}
            {cityTreatments.length >= 6 &&
              <span className={css.treatment}>
                <strong>
                  Show more
                </strong>
                <img className={css.arrowIcon} src={ArrowDownIcon} />
              </span>
            }
          </div>
        </div> */}
        <div className={css.bottomWrapper}>
          <H2 className={css.mainTitle}>
            <FormattedMessage
              id="Footer.mainTitle"
              values={{ lineBreak: <br /> }}
            />
          </H2>
          <div className={css.contact}>
            <span className={css.contactText}>
              <FormattedMessage id="Footer.contactText" />
            </span>
            <div className={css.emailInput}>
              <div>
                <label><FormattedMessage id="Footer.emailLabel" /></label>
                <input
                  type="email"
                  placeholder="join@heyblumi.com"
                />
              </div>
              <img src={ArrowIcon} />
            </div>
            <p className={css.copyright}>
              <FormattedMessage id="Footer.copyright" />
            </p>
          </div>
        </div>
      </div>
    </SectionContainer >
  );
};

const propTypeOption = shape({
  fieldComponents: shape({ component: node, pickValidProps: func }),
});

SectionFooter.defaultProps = {
  className: null,
  rootClassName: null,
  textClassName: null,
  numberOfColumns: 1,
  socialMediaLinks: [],
  slogan: null,
  copyright: null,
  appearance: null,
  blocks: [],
  options: null,
};

SectionFooter.propTypes = {
  // sectionId: string.isRequired,
  className: string,
  rootClassName: string,
  numberOfColumns: number,
  socialMediaLinks: arrayOf(object),
  slogan: object,
  copyright: object,
  appearance: object,
  blocks: arrayOf(object),
  options: propTypeOption,
};

export default SectionFooter;
